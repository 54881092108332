import { Application } from "@hotwired/stimulus";
import "@hotwired/turbo-rails";
import "../confirm";
import { registerControllers } from "../registerControllers";

document.addEventListener("turbo:submit-start", function (event) {
  document.querySelector("html").ariaBusy = true;
});

document.addEventListener("turbo:submit-end", function (event) {
  document.querySelector("html").ariaBusy = false;
});

// stimulus
const application = Application.start();
application.warnings = false;
application.debug = false;

import Clipboard from "stimulus-clipboard";
application.register("clipboard", Clipboard);

registerControllers(
  application,
  import.meta.globEager(
    "../../../app/components/legacy_ui/**/*_controller.{js,ts}",
  ),
);
registerControllers(
  application,
  import.meta.globEager(
    "../../../app/components/salon/**/*_controller.{js,ts}",
  ),
);
registerControllers(
  application,
  import.meta.globEager(
    "../../../app/components/hairbook/**/*_controller.{js,ts}",
  ),
);

import VideoController from "../controllers/video_controller";
application.register("video", VideoController);

import TrackingController from "../controllers/tracking_controller";
application.register("tracking", TrackingController);

import VisibilityController from "../controllers/visibility_controller";
application.register("visibility", VisibilityController);
